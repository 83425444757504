.vh-5 {
  height: 8vh;
}
.vh-95 {
  height: 92vh;
}
.vh-25 {
  height: 20vh;
}
.vh-50 {
  height: 50vh;
}
.vh-75 {
  height: 75vh;
}
